import { Alert, background, Box, Button, ButtonGroup, Checkbox, Container, Grid, GridItem, Heading, HStack, Image, Input, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Spacer, Spinner, Stack, Text, useColorMode, useColorModePreference, useColorModeValue, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { ethers } from "ethers";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as constants from "../constants"
import { useParams } from "react-router-dom"
import abi from "../abis/wavax.json"



const shorten = (add) => {
    return add.slice(0, 5) + "..." + add.slice(-4)
}



export default function Mint() {

    const provider = constants.n43114.Provider
    const signer = constants.n43114.Signer
    const contractAddress = '0x165332388eE5eAeF53F94f9fe2da1d790658B11a'
    const contract = useMemo(() => new ethers.Contract(contractAddress, abi, signer), [signer])
    const wavaxAddress = '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7'
    const wavax = useMemo(() => new ethers.Contract(wavaxAddress, abi, signer), [signer])
    const toast = useToast()
    const [address, setAddress] = useState("")
    const [balance, setBalance] = useState(0)
    const [NFTs, setNFTs] = useState()
    const [amount, setAmount] = useState(1)
    const handleError = (err) => {
        toast({
            title: "Error",
            description: (err.reason || err.message),
            status: "error",
            duration: 9000,
            isClosable: true,
        })
    }

    const connect = useCallback(async () => {
        try {
            await provider.request({
                method: "wallet_requestPermissions",
                params: [{
                    eth_accounts: {}
                }]
            }).then(() => provider.request({
                method: 'eth_requestAccounts'
            }))
        } catch (err) {
            console.log(err)
        }
        try {
            await provider.enable()

        } catch (err) {
            console.log(err)
        }
    }, [provider])





    useEffect(() => {
        constants.changeNetwork(43114)
        signer.getAddress().then(setAddress)
    }, [NFTs, address, connect, signer])

    useMemo(()=>{
        if (address) {
            fetch("https://deep-index.moralis.io/api/v2/" + address + "/nft?chain=0xa86a&token_addresses=0x165332388eE5eAeF53F94f9fe2da1d790658B11a", { method: "GET", headers: { accept: 'application/json', 'X-API-Key': "rTO3ivS5kbrUYLADjiK07aprt07UIKh9lcdEy346azAzfSGi6zfYLBA4z9C1MuE4" } }).then((nfts) => {
                nfts.json().then(e => {
                    setNFTs(e.result)
                })

            })
        }
    }, [address])


    const mintWithAvax = async () => {
        const tx = await contract.mintWithAvax({ value: ethers.utils.parseEther(amount) }).catch(handleError)
        await tx.wait()
        toast({
            title: "Success",
            description: `Minted a ${amount} WAVAX NFT!`,
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }

    const mint = async () => {
        const tx = await contract.mint(ethers.utils.parseEther(amount)).catch(handleError)
        await tx.wait()
        toast({
            title: "Success",
            description: `Minted a ${amount} WAVAX NFT!`,
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }

    const approve = async () => {
        const tx = await wavax.approve(contractAddress, ethers.utils.parseEther(amount)).catch(handleError)
        await tx.wait()
        toast({
            title: "Success",
            description: `Approved ${amount} WAVAX!`,
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }

    const redeem = async (tokenId)=>{
        const tx = await contract.redeem(tokenId).catch(handleError)
        await tx.wait()
        toast({
            title: "Success",
            description: `Redeemed token for WAVAX!`,
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }

    return (
        <Container maxW="container.xl" centerContent p='50' gap={2}>
            <Heading>Wrap AVAX</Heading>
            <Button onClick={connect}>{address ? shorten(address) : 'Connect'}</Button>
            <Text>Amount to wrap: (in avax)</Text>
            <Input width={'400px'} placeholder="Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
            <ButtonGroup>
                <Button onClick={mintWithAvax}>Mint With AVAX</Button>
                <VStack>
                    <Button onClick={mint}>Mint With WAVAX</Button>
                    <Button onClick={approve}>Approve {amount} WAVAX</Button>
                </VStack>
            </ButtonGroup>
            <Heading>Unwrap AVAX</Heading>
            <Alert status="warning" width={'400px'}>There's a 5% tax when unwrapping</Alert>
            <SimpleGrid autoColumns={'auto'} minChildWidth={'100px'} >
            {NFTs?.map((nft, index)=>{
                const metadata = JSON.parse(nft.metadata)
                return(
                    <GridItem textAlign={'center'}>
                    <Image width={'200px'} src={metadata.image}/>
                    <Text>{metadata.name}</Text>
                    <Button onClick={()=> redeem(nft.token_id)}>Unwrap</Button>
                    </GridItem>
                )
            })}
            </SimpleGrid>
        </Container>

    );
}